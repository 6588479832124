import React from "react";
import "./OurTechStack.css";
import caret_right from "../../assets/caret-right-fill.svg";

export const OurTechStack = () => {
  return (
    <>
      <div id="techstack"></div>

      <div className="techstack_container">
        <div className="techstack_title">
          <h2>Our Technology Stack</h2>
        </div>

        <div className="techstack-brief">
          <span>
            Happy Toro are made up of a team of highly experience and proficient developers that are capable of providing solutions with 
            the latest technology stacks. Our expertise spans all major technologies and platforms, and advances to innovative technology trends.
          </span>
        </div>

        <div className="techstack_catalog">
          <div className="techstack_container">
            <img src={require("../../assets/tech-stack.png")} alt="tech-stack" />
          </div> 
        </div>
      </div>
    </>
    
  );
};

{/* <div className="ourtechstack__title2">
        <h2>Our Tech Stack</h2>
      </div>

      <div className="ourtechstack__content2">
        <div className="ourtechstack__content-list">
          <div className="ourtechstack__content-list__skill1">
            <div className="caret-right">
              <img src={caret_right} alt="" />
            </div>
            <div className="skill">
              <p>
                Use any language, such as JavaScript and its frameworks (i.e., React, Nest), Golang, to produce full-stack applications.
              </p>
            </div>
          </div>
          <div className="ourtechstack__content-list__skill2">
            <div className="caret-right">
              <img src={caret_right} alt="" />
            </div>
            <div className="skill">
              <p>
                Prioritize EVM compatibility through the usage of Solidity as
                the primary smart contract language.
              </p>
            </div>
          </div>
          <div className="ourtechstack__content-list__skill3">
            <div className="caret-right">
              <img src={caret_right} alt="" />
            </div>
            <div className="skill">
              <p>
                Utilize Ethereum and Hyperledger open-source ecosystem to create
                private blockchains solutions for clients.
              </p>
            </div>
          </div>
          <div className="ourtechstack__content-list__skill4">
            <div className="caret-right">
              <img src={caret_right} alt="" />
            </div>
            <div className="skill">
              <p>
                Store data through a myriad of database servers (i.e., MSSQL,
                PostgreSQL, MongoDB).
              </p>
            </div>
          </div>
          <div className="ourtechstack__content-list__skill5">
            <div className="caret-right">
              <img src={caret_right} alt="" />
            </div>
            <div className="skill">
              <p>
                Integrate AWS services (i.e., EC2, S3, EKS) to deliver applications.
              </p>
            </div>
          </div>
          <div className="ourtechstack__content-list__skill6">
            <div className="caret-right">
              <img src={caret_right} alt="" />
            </div>
            <div className="skill">
              <p>
                Deployment of applications through containers, kubernetes, helm packages.
                Cloud infra through terraform.
              </p>
            </div>
          </div>
        </div>
      </div> */}
