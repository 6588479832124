import React from "react";
import { slide as Menu } from "react-burger-menu";
import "./Sidebar.css";
import { NavHashLink } from "react-router-hash-link";

const Sidebar = (props: any) => {
  return (
      <Menu right>
        <NavHashLink smooth to="/#services">
          Services
        </NavHashLink>
        <NavHashLink smooth to="/#about">
          About
        </NavHashLink>
        <NavHashLink smooth to="/#projects">
          Projects
        </NavHashLink>
        <NavHashLink smooth to="/#contact">
          Contact Us
        </NavHashLink>
        <NavHashLink smooth to="/#techstack">
          TechStack
        </NavHashLink>
      </Menu>
  );
};

export default Sidebar;
