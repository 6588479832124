import { Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Home from "./components/Home/Home";
import { About } from "./components/About/About";
import { Header } from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { BondToken } from "./components/BondToken/BondToken";
import "./App.css";

function App() {
  return (
    <>
      <div className="component-container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/bond-token" element={<BondToken />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default App;
