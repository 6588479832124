import React from "react";
import "./Services.css";
import caret_right from "../../assets/caret-right-fill.svg";

export const Services = () => {
  return (
    <>
      <div id="services">t</div>
      <div className="services_container">

        <div className="service_title">
          <h2>Our Specializations</h2>
        </div>

        <div className="services_catalog">

          <div className="services-catalog_container">
            <div className="service-icon">
              <img src={require('../../assets/blockchain2-icon.png')} alt="" />
            </div>
            <h4 className="service_title">Blockchain Development</h4>
            <span>
              We help banking businesses who aim to be on track with the latest fintech innovation.
              <br /><br />
              Discover Happy Toro's end-to-end blockchain solutions to digitize financial products, assets, 
              and transactions securely. 
            </span>
          </div>

          <div className="services-catalog_container">
            <div className="service-icon">
              <img src={require('../../assets/cloud-icon.png')} alt="" />
            </div>
            <h4 className="service_title">Cloud-based Solutions</h4>
            <span>
              We are specialized in providing cloud solutions to our clients.
              <br /><br />
              We provide the perfect cloud architecture from the design to complete integration
              which suits our clients needs.
            </span>
          </div>

          <div className="services-catalog_container">
            <div className="service-icon">
              <img src={require('../../assets/api-icon.png')} alt="" />
            </div>
            <h4 className="service_title">API Integration</h4>
            <span>
              Provide API development which allows the abstraction of functionality between one system and another.
              <br /><br />
              This allows clients to have flexibility on service offerings. The infrastructure 
              behind the API are able to communicate with a blockchain.
            </span>
          </div>
        </div>

        <div className="service_brief">
          <span>
              The possibilities of blockchain technology are truly endless, and developments in recent years 
              have taken us one step closer to a trustless internet, transparency of transactions, and more.
          </span>
          <br />
          <span>
              Blockchain technology is expected to revolutionise the way we do business across all
              sectors such as healthcare, government, retail and more.
              It will be interesting to see what the future holds for blockchain technology, especially regarding money transfers, 
              banking services, decentralized marketplaces, and more.
          </span>
        </div>

      </div>
    </>
  );
};
