import { Link, useMatch, useResolvedPath } from "react-router-dom";
import "./Navbar.css";
import Sidebar from "../Sidebar/Sidebar";
import { NavHashLink } from "react-router-hash-link";

const Navbar = (props: any) => {
  return (
    <>
      <nav className="nav">
        <div className="nav_logo-container">
          <img src={require('../../assets/blockchain-icon.png')} alt="icon" className="logo-icon" />
          <NavHashLink smooth to="#home" className="nav-logo">
            Happy<span>Toro</span>
          </NavHashLink>
        </div>
        <div className="nav-links">
          <ul>
            <li>
              <NavHashLink smooth to="#services">
                Services
              </NavHashLink>
            </li>
            <li>
              <NavHashLink smooth to="#about">
                About
              </NavHashLink>
            </li>
            <li>
              <NavHashLink smooth to="#projects">
                Projects
              </NavHashLink>
            </li>
            <li>
              <NavHashLink smooth to="#contact">
                Contact Us
              </NavHashLink>
            </li>
            <li>
              <NavHashLink smooth to="#techstack">
                TechStack
              </NavHashLink>
            </li>
          </ul>
        </div>
        <div className="sidebar">
          <Sidebar />
        </div>
      </nav>
    </>
  );
};

export default Navbar;
