import "./Projects.css";
import BondTokenBanner from "../../assets/project-showcase-1.png";
import { Link } from "react-router-dom";

export const Projects = () => {
  return (
    <>
      <div id="projects"></div>
      <div className="project_container">
        <div className="project-header">
          <h2 className="project-title">
            Project Showcase
          </h2>
          <span className="project-brief">
            Happy Toro provides a full-fledged solutions to our business clients according to their needs. We have sophisticated
            experience in building complex applications with modern technology stacks.
          </span>
        </div>
        <div className="projects_catalog">
          <Link to={{pathname: "/bond-token"}}>
            <div className="project_card">
              
              <img src={BondTokenBanner} />
              
              <div className="filter">
                <span className="project-name">
                  Bond Tokenization Mobile Application
                </span>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};
