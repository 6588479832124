import React from "react";
import "./Header.css";
import { NavHashLink } from "react-router-hash-link";

export const Header = () => {
  return (
    <>
      <div id="home"></div>
      <div className="bc__bg-image">
        <div className="htwebpage__header-welcometext">
          <h4>Your Key to Blockchain Solutions</h4>
          <span className="header-desc">
            Happy Toro provides blockchain solutions to banking industry worldwide.
            <br /><br />
            Specialize in helping banking venturing into blockchain technology. Proficient in tokenize assets to create fungibility 
            and increase liquidity. Easily manage your digital assets throughout their lifecycle, with our compliant end-to-end 
            tokenization infrastructure. 
          </span>
          <br />
          <NavHashLink smooth to="#contact" className="header-btn">Contact Us</NavHashLink>
        </div>
    </div>
    </>
    
  );
};
