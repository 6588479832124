import { Header } from "../Header/Header";
import { About } from "../About/About";
import Navbar from "../Navbar/Navbar";
import { OurTechStack } from "../OurTechStack/OurTechStack";
import { Services } from "../Services/Services"
import { Contact } from "../Contact/Contact";
import { Projects } from "../Projects/Projects";
import "./Home.css";

const Home = () => {
  return (
    <div className="home__container">
      <Navbar />
      <Header />
      <Services />
      <About />
      <Projects />
      <Contact />
      <OurTechStack />
    </div>
  );
};

export default Home;
