import "./Footer.css";
// import happyToroLogo from "../../assets";

const Footer = () => {
  return (
    <>
      <div className="footer_lower">
        <div className="footer-links">
          <div className="footer-links_div">
            <h4>Links</h4>
            <span>
              Social media
              <br />
              Contact
            </span>
          </div>

          <div className="footer-links_div">
            <div className="">
              <h4>Company</h4>
            </div>

            <div className="footer-links_div">
              <span>
                Terms and conditions
                <br />
                Privacy
                <br />
                Contact
              </span>
            </div>
          </div>

          <div className="footer-links_div">
            <h4>Location</h4>
        
            <span>
              MY Office: Kuala Lumpur, Malaysia
              <br />
              SG Office: Raffles Place, Singapore
            </span>
          </div>
        </div>

        <div className="footer-copyright">
          <p>&copy; 2022 Happy Toro. All rights reserved.</p>
        </div>

      </div>
    </>
  );
};

export default Footer;
