import React from "react";
import Homepage from "../../assets/bondTokenApp/homepage.png";
import Sell from "../../assets/bondTokenApp/sell.png";
import Trade from "../../assets/bondTokenApp/trade.png";
import SellPage from "../../assets/bondTokenApp/sellPage.png";
import TradeConfirmation from "../../assets/bondTokenApp/tradeConfirmation.png";
import TradeSuccess from "../../assets/bondTokenApp/tradeSuccess.png";
import LandingPage from "../../assets/bondTokenApp/landingPage.png";
import RegistrationSuccess from "../../assets/bondTokenApp/registrationSuccess.png";
import Navbar from "../Navbar/Navbar";
import "./BondToken.css";

export const BondToken = () => {
  const handleDemoClick = () => {
    window.location.href = "mailto:sales@happy-toro.com";
  };
  return (
    <>
     <nav className="nav">
        <div className="nav_logo-container">
          <img src={require('../../assets/blockchain-icon.png')} alt="icon" className="logo-icon" />
          <a href="/" className="nav-logo">
            Happy<span>Toro</span>
          </a>
        </div>
      </nav>
      <div id="start" className="base-container">
        <h1 className="header">Tokenizing Bonds for Global Reach.</h1>
        <div className="image-container">
          <img src={LandingPage} className="image" />
          <img src={RegistrationSuccess} className="image" />
        </div>

        <h1 className="header">
          Simplifying your access to bonds offered by banks worldwide.
        </h1>
        <div className="image-container">
          <img src={Homepage} className="image" />
          <img src={Trade} className="image" />
        </div>

        <h1 className="header">Buy and Sell Tokenized Bonds with ease.</h1>
        <div className="image-container">
          <img src={Sell} className="image" />
          <img src={SellPage} className="image" />
          <img src={TradeConfirmation} className="image" />
          <img src={TradeSuccess} className="image" />
        </div>

        <div className="button-container">
          <button className="BTbutton" onClick={handleDemoClick}>
            Request for Demo
          </button>
        </div>
      </div>
    </>
  );
};
