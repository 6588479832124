import "./About.css";
import { NavHashLink } from "react-router-hash-link";

export const About = () => {
  return (
    <>
      <div id="about"></div>
      <div className="about_container">
        <div className="about_title">
          <h2>
            Company Profile
          </h2>
        </div>
        <div className="about_content">

          <div className="about-content__skillset">
            <span>
              Happy Toro is a company consisting of architects and engineers that are proficient in blockchain development. 
              We specialize in developing blockchain solutions for the banking industry, both on-premises and cloud based. 
              <br /><br />
              We communicate on a high-value basis with clients to achieve their intended requirements by integrating cutting-edge 
              technologies in blockchain ecosystem into deliverable. We also embrace Agile methodology and DevOps pipelines 
              in our project implementation.
            </span>
          </div>

          <div className="about-content__cta">
            <NavHashLink smooth to="#contact" className="aboutBtn">Learn More</NavHashLink>
          </div>
          
        </div>
      </div>
    </>
  );
};
